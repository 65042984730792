import { ref, Ref } from 'vue';
import {  $loading, $upload } from '@/api/index';
import { toast } from '@/utils/toast';
import { setDateFormat } from '@/utils/dateFormatter';

export interface CloseY {
  sq: string;
  yymm: string;
  saleCode: string;
  saleName: string;
  dplaceCode: string;
  dplaceName: string;
  itnmcode: string;
  itnm: string;
  saleQunt: number;
  taxQunt: number;
  sunQunt: number;
  endynNm: string;
  endYn: string;
}


export function useClose() {
  const items: Ref<CloseY[]> = ref([]);
  const year = ref(setDateFormat(new Date(), 'YYYY'));
  const month = ref(setDateFormat(new Date(), 'MM'));
  const LIMIT = 10;
  const next = ref(LIMIT);
  const isConnected = ref(true);

  async function fetchDatas(frDate: string, dpTitle: string) {
    await $loading(`/close?frDate=${frDate}&dpcode=${dpTitle}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          items.value = [];
          isConnected.value = false;
          toast('데이터가 존재하지 않습니다.'); 
        } else {

          items.value = null;
          items.value = res.data;
          isConnected.value = true;
        
        }
        items.value = res.data;
      }
    );
  }

  async function insertSign(data: any) {
    const fm = new FormData();
    fm.append('yymm', data.yymm);
    fm.append('saleCode', data.saleCode);
    fm.append('dplaceCode', data.dplaceCode);
    fm.append('itnmcode', data.itnmcode);
    fm.append('taxQunt', data.taxQunt);
    fm.append('sunQunt', data.sunQunt);
    await $upload('/close/sign', fm).then((res: any) => {
      if (res.status == 200) {
        toast('마감 완료');
        items.value = res.data;
      }
    }).catch(err => {
      if (err.response.status == 404) {   
        alert(err.response.data.message);
      } else if (err.response.status == 500) {
        toast(
          '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
          5000
        );
      }
    });
  }

  async function deleteSign(data: any) {
    const fm = new FormData();
    fm.append('yymm', data.yymm);
    fm.append('saleCode', data.saleCode);
    fm.append('dplaceCode', data.dplaceCode);
    fm.append('itnmcode', data.itnmcode);
    fm.append('taxQunt', data.taxQunt);
    fm.append('sunQunt', data.sunQunt);
    await $upload('/close/delete', fm).then((res: any) => {
      if (res.status == 200) {
        toast('취소 완료');
        items.value = res.data;
      }
    }).catch(err => {
      if (err.response.status == 404) {   
        alert(err.response.data.message);
      } else if (err.response.status == 500) {
        toast(
          '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
          5000
        );
      }
    });
  }
  async function insertSignAll(mode: string,frDate: string) {
    await $loading(`/close/signAll?mode=${mode}&frDate=${frDate}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          items.value = [];
          toast('데이터가 존재하지 않습니다.'); 
        } else {
          items.value = null;
          items.value = res.data;
          toast('일괄처리가 완료되었습니다.'); 
        }
        
      }
    ).catch(err => {
      if (err.response.status == 406) {   
        alert(err.response.data.message);
      } else if (err.response.status == 500) {
        toast(
          '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
          5000
        );
      }
    });
  }

  return {
    LIMIT,
    next,
    items,
    fetchDatas,
    insertSign,
    deleteSign,
    year,
    month,
    isConnected,
    insertSignAll
  };
}
